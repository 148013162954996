import axios from "axios";

const instance = axios.create({
  // baseURL: `https://www.gypsy.developer.bdigismat.com`,
  // baseURL: `http://d.tusongem.api/`
  baseURL: ((window.location.hostname=='localhost' || window.location.hostname=='d.tusongem.pre') ? `http://d.tusongem.api/` : `https://api.tusongembele.nanesoft-lab.com/`)
  // timeout: 2
});

export default instance;
