export const nigeriaStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const workSector = [
  "Agriculture",
  "Construction/ Real Estate",
  "Consumer Goods",
  "Financial Services",
  "Healthcare",
  "Industrial Goods",
  "Information and Communication Technology",
  "Natural Resources",
  "Oil and Gas",
  "Services",
  "Utilities",
  "Others",
];

export const workIndustries = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",
];

export const faqContent = [
  {
    title:
      "Am I eligible for a Tusonge Mbele Loans loan? / why am I not qualified for a loan?",
    bodyContent: () => (
      <>
        <p>To qualify for a loan, you must be:</p>
        <ul>
          <li>Be between 25 – 59 years old</li>
          <li>Have a verifiable source of income</li>
          <li>Live and work in cities where we operate</li>
          <li>
            Have a savings or current bank account with any commercial bank
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "How much can I borrow and for how long",
    bodyContent: () => <p>You can borrow N250,000 for 3 months</p>,
  },
  {
    title: "How do I apply?",
    bodyContent: () => (
      <p>
        Please visit our website www.gypsycapital.com to apply or call
        +2349038406446.
      </p>
    ),
  },
  {
    title: "How long does it take to process a loan?",
    bodyContent: () => (
      <p>
        Your loan process can be completed in 24 hours provided all documents
        are valid & presented on application.
      </p>
    ),
  },
  {
    title: "What documents do I need to apply for a loan?",
    bodyContent: () => (
      <p>
        You need your bank statement, a government issued ID card and utility
        bill (optional).
      </p>
    ),
  },
  {
    title: "Why does Tusonge Mbele Loans Need my BVN?",
    bodyContent: () => (
      <p>
        This is a CBN policy applicable to financial institutions as part of KYC
        requirements.
      </p>
    ),
  },
  {
    title: "Why can you no longer proceed with my loan application?",
    bodyContent: () => (
      <p>
        Based on the information provided by you, you do not meet the minimum
        requirements to access a loan.
      </p>
    ),
  },
  {
    title: "Why was my loan application not approved?",
    bodyContent: () => (
      <p>
        Based on the information provided by you, you do not meet the minimum
        requirements to access a loan.
      </p>
    ),
  },
  {
    title: "How does Tusonge Mbele Loans Calculate Interest Rate?",
    bodyContent: () => (
      <p>
        Tusonge Mbele Loans uses a Risk Based Pricing model which estimates the risk
        of lending to each applicant and prices accordingly.
      </p>
    ),
  },
  {
    title: "How long can I pay back my Loan?",
    bodyContent: () => <p>Our maximum loan repayment tenure is 3 months.</p>,
  },
  {
    title: "When will I get my loan?",
    bodyContent: () => (
      <p>
        Once your application is approved, you will receive funds within 24
        hours.
      </p>
    ),
  },
];

export const limitFaqContent = (limit) => {
  return faqContent?.slice(0, limit);
};

export const numberWithCommas = (x) => {
  x = x?.toFixed(2);
  x = x?.toString().split(".");
  if (x) {
    return `${x[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${x[1]}`;
  }
  return null;
};
