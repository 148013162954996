import React, { useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import styles from "./HomePage.module.scss";
import { Row, Col } from "react-bootstrap";
// import ExcitedCustomer from "../../assets/excitement.jpeg";
import ExcitedCustomer from "../../assets/logo.png";
import ExcitedCustomerMinor from "../../assets/excitement-2.jpg";
import Clients from "../../assets/clients.jpg";
import Circle from "../../assets/circle.svg";
import Pattern from "../../assets/pattern.svg";
import AboutImg from "../../assets/banner1.jpg";
import { FaMoneyBillWaveAlt, FaStopwatch, FaFileAlt } from "react-icons/fa";
import { HiLocationMarker, HiMail, HiPhone } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import Credit from "../../assets/icons/BusinessLoan.png";
import Notes from "../../assets/icons/FixedDeposit.png";
import Advisory from "../../assets/icons/SavingsAccount.png";
import Alternative from "../../assets/icons/CreditCards.png";
import CirclePattern from "../../assets/patternCircle.png";
import InputField from "../../components/InputField/InputField";
import FaqSection from "../../components/FaqSection/FaqSection";
import Footer from "../../components/Footer/Footer";
import { useRouteMatch, useHistory } from "react-router-dom";
import { convertInput } from "../../utils/convertInputType";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import LazyLoad from "react-lazyload";

const HomePage = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [loanAmt, setLoanAmt] = useState("");
  const [loanRequest, setLoanRequest] = useState("");

  const [contactInfo, setContactInfo] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  return (
    <>
      <ScrollToTopOnMount />
      <NavBar history={history} location={url} />
      <div className={styles.heroSection}>
        <Row className={styles.container}>
          <Col md={6} sm={12} className={styles.mainCopy}>
            <div className={styles.copyGroup}>
              <h1>Tusonge Mbele.</h1>
              <h3>A premier business startup and growth support entity</h3>
              <input
                type="text"
                name="loanAmt"
                value={loanAmt}
                placeholder="How much do you need?"
                onChange={(e) => {
                  const { includesAlphabet, convertedToNumber } = convertInput(
                    e.currentTarget.value
                  );
                  if (!includesAlphabet) {
                    setLoanAmt(convertedToNumber.toLocaleString());
                  }
                }}
              />
              <button
                onClick={() =>
                  history.push({
                    pathname: "/signup",
                    state: { loanAmount: loanAmt },
                  })
                }
              >
                Get Started
              </button>
              <img src={Pattern} className={styles.pattern} alt="pattern" />
            </div>
          </Col>
          <Col md={6} sm={12} className={styles.imageGroup}>
            <div className={styles.imgContainer}>
              <LazyLoad height={200} once>
                <img
                  className={styles.mainImg}
                  src={ExcitedCustomer}
                  alt="An excited customer"
                />
              </LazyLoad>
              {/* <LazyLoad height={200} once>
                <img
                  className={styles.minorImg}
                  src={ExcitedCustomerMinor}
                  alt="An excited customer"
                />
              </LazyLoad> */}
              {/* <LazyLoad height={200} once>
                <img
                  className={styles.minorImg2}
                  src={Clients}
                  alt="Excited customers"
                />
              </LazyLoad> */}
              <LazyLoad once>
                <img
                  src={Circle}
                  className={styles.firstCircle}
                  alt="presentation"
                />
              </LazyLoad>
              <LazyLoad once>
                <img
                  src={Circle}
                  className={styles.secondCircle}
                  alt="presentation"
                />
              </LazyLoad>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.aboutSection}>
        <Row className={styles.container}>
          <Col sm={12} md={6} className="mb-5">
            <h2>Small Business Loans at Affordable rates</h2>
            <p>
              We envision a financially inclusive world where all people hold the power to improve their lives.
            </p>
            <p>
              We do this by unlocking capital to the underserved and addressing the underlying barriers to financial access.
            </p>
          </Col>
          <Col sm={12} md={6} className={styles.imageGroup}>
            <div className={styles.imageContainer}>
              <img src={AboutImg} alt="About Gypsy" />
              <div className={styles.rect}></div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.productSection}>
        <div className={styles.container}>
          <h5>Our Services</h5>
          <Row>
            <Col sm={12} lg={6} className="mb-5">
              <div
                className={styles.productBox}
                style={{ borderBottom: "4px solid #1F8DE8" }}
              >
                <div className={styles.iconWrapper}>
                  <img
                    src={Credit}
                    alt="Consumer Credit"
                    className={styles.credit}
                  />
                </div>
                <h3>Business Loan</h3>
                <p>Business Concept Development & Small Business Management Capacity Building</p>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div
                className={styles.productBox}
                style={{ borderBottom: "4px solid #841FE8" }}
              >
                <div className={styles.iconWrapper}>
                  <LazyLoad once offset={100}>
                    <img
                      src={Notes}
                      alt="Gypsy Notes"
                      className={styles.notes}
                    />
                  </LazyLoad>
                </div>
                <h3>Start Up and Expansion Loans</h3>
                <p>Small Business Start Up  and Expansion loans (Inua, Wezesha and Imarisha Biashara pacakges)</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12} lg={6} className="mb-5">
              <div
                className={styles.productBox}
                style={{ borderBottom: "4px solid #FF5800" }}
              >
                <div className={styles.iconWrapper}>
                  <LazyLoad once offset={100}>
                    <img
                      src={Advisory}
                      alt="Financial Advisory"
                      className={styles.advisory}
                    />
                  </LazyLoad>
                </div>
                <h3>Savings Account</h3>
                <p>...</p>
              </div>
            </Col>
            <Col sm={12} lg={6} className="mb-5">
              <div
                className={styles.productBox}
                style={{ borderBottom: "4px solid #015514" }}
              >
                <div className={styles.iconWrapper}>
                  <LazyLoad once offset={100}>
                    <img
                      src={Alternative}
                      alt="Alternative Investment"
                      className={styles.alternative}
                    />
                  </LazyLoad>
                </div>
                <h3>Linkage Services</h3>
                <p>Point of Entry Linkage Services to SME and MSME enabler organizations.</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FaqSection returnNumber={4} /> */}
      <div className={styles.contactSection}>
        <div className={styles.container}>
          <h2>Contact</h2>
          <Row className={styles.contactContainer}>
            <Col className={styles.addressCol}>
              <div className={styles.addressDetails}>
                <h3>Get In Touch</h3>
                <div className={styles.contactGroup}>
                  <HiLocationMarker className={styles.icon} size="4em" />
                  <p>
                  Kibera Drive,
                  Nairobi - Kenya.
                  </p>
                </div>
                <div className={styles.contactGroup}>
                  <HiMail className={styles.icon} size="1.8em" />
                  <p>admin@tusongembele.co.ke</p>
                </div>
                <div className={styles.contactGroup}>
                  <HiPhone className={styles.icon} size="1.8em" />
                  <p>020 200 0396</p>
                </div>
                <div className={styles.contactGroup}>
                  <IoLogoWhatsapp className={styles.icon} size="1.8em" />
                  <p>+254 020 200 0396</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className={styles.contactForm} style={{display:"none"}}>
                <h3>Send Us A Message</h3>
                <div className={styles.inputWrapper}>
                  <InputField
                    placeholder="Name"
                    name="name"
                    type="text"
                    value={contactInfo.name}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, name: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputField
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={contactInfo.email}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, email: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputField
                    placeholder="Subject"
                    name="subject"
                    type="text"
                    value={contactInfo.subject}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, subject: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputField
                    type="textarea"
                    nameAttr="message"
                    placeholder="Message"
                    value={contactInfo.message}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, message: val })
                    }
                  />
                </div>
                <button>Submit</button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.subFooter}></div>
      <Footer />
    </>
  );
};

export default HomePage;
