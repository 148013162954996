import React from "react";
import styles from "./Footer.module.scss";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import PlainLogo from "../../assets/logo-white.png";
import { Link } from "react-router-dom";
import pageUrl from "../../routes/pageUrl";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <div className={styles.container}>
        <img src={PlainLogo} alt="Gypsy Logo" />
        <div className={styles.socialLinks}>
          <a href="#">
            <FaFacebookF size="1.4em" />
          </a>
          <a href="https://twitter.com/TusongeV" target="blank">
            <FaTwitter size="1.4em" />
          </a>
          <a
            href="#"
            target="blank"
          >
            <FaInstagram size="1.4em" />
          </a>
          <a href="https://www.linkedin.com/company/82097327">
            <FaLinkedin size="1.4em" />
          </a>
        </div>
        <div className={styles.footerLinks}>
          <ul>
            <li>
              <Link to={pageUrl.PRIVACY_POLICY_PAGE}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={pageUrl.TERMS_CONDITIONS_PAGE}>Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <p className={styles.copyright}>
          &copy; Tusonge Mbele Loans. <br/>
          &copy; {new Date().getFullYear()} <a href="https://nanesoft-lab.com/" style={{color:"#ffffff", textDecoration:"underline"}} target="_">Nanesoft Labs&trade;</a>. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
