import React, { useState, useContext, useEffect, useMemo } from "react";
import InputField from "../InputField/InputField";
import { Row, Col } from "react-bootstrap";
import styles from "./BvnForm.module.scss";
import { Context as UserContext } from "../../context/UserContext";
import { Context as BankContext } from "../../context/BankCotext";
import Button from "../Button/Button";
import { validateInput } from "../../utils/validateInput";

const BvnForm = ({ submit }) => {
  const [verificationData, setVerificationData] = useState({
    userBvn: "",
    // mpesaPhone: "",
    businessName: "",
    businessLoc: "",
    products: "",
    description: ""
  });
  const [verificationError, setVerificationError] = useState({
    userBvn: null,
    // mpesaPhone: null,
    businessName: null,
    businessLoc: null,
    products: null,
    description: null
  });
  const {
    state: { loading },
  } = useContext(UserContext);

  const {
    state: { paystackBanks },
    getPaystackBankList,
  } = useContext(BankContext);

  const bankNames = useMemo(() => {
    return paystackBanks.map((bank) => bank.name);
  }, [paystackBanks]);

  useEffect(() => {
    getPaystackBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyCustomer = () => {
    const validated = validateInput(verificationData, setVerificationError);

    // let bankCode = paystackBanks.filter(
    //   (bank) =>
    //     bank.name.toLowerCase() === verificationData.bankName.toLowerCase()
    // )[0].code;

    if (validated) {
      const data = {
        bvn: verificationData.userBvn,
        // mpesaPhone: verificationData.mpesaPhone,
        businessName: verificationData.businessName,
        businessLoc: verificationData.businessLoc,
        products: verificationData.products,
        description: verificationData.description
      };
      submit(data);
    }
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      verifyCustomer();
    }
  };

  return (
    <div className={styles.bvnFormBox}>
      <p>
        Know-Your-Customer (KYC) requirements.
      </p>
      <Row className="mb-4">
        <Col>
          <InputField
            label="What's your ID number?"
            nameAttr="bvn"
            type="text"
            value={verificationData.userBvn}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, userBvn: null });
              setVerificationData({ ...verificationData, userBvn: val });
            }}
            error={verificationError?.userBvn}
          />
        </Col>
      </Row>
      {/* <Row className="mb-4">
        <Col>
          <InputField
            label="M-Pesa Phone number"
            nameAttr="mpesaPhone"
            type="numbet"
            value={verificationData.mpesaPhone}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({
                ...verificationError,
                mpesaPhone: null,
              });
              setVerificationData({ ...verificationData, mpesaPhone: val });
            }}
            error={verificationError?.mpesaPhone}
          />
        </Col>
      </Row> */}
      <Row className="mb-4">
        <Col>
          <InputField
            label="Business Name"
            nameAttr="businessName"
            type="text"
            value={verificationData.businessName}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, businessName: null });
              setVerificationData({ ...verificationData, businessName: val });
            }}
            error={verificationError?.businessName}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InputField
            label="Business Location"
            nameAttr="businessLoc"
            type="text"
            placeholder="County, City or town, Street, Building"
            value={verificationData.businessLoc}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, businessLoc: null });
              setVerificationData({ ...verificationData, businessLoc: val });
            }}
            error={verificationError?.businessLoc}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InputField
            label="Products or services you sell"
            nameAttr="products"
            placeholder="Eg. Cups, plates, spoons"
            type="text"
            value={verificationData.products}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, products: null });
              setVerificationData({ ...verificationData, products: val });
            }}
            error={verificationError?.products}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InputField
            label="Business description"
            nameAttr="description"
            type="textarea"
            value={verificationData.description}
            // handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, description: null });
              setVerificationData({ ...verificationData, description: val });
            }}
            error={verificationError?.description}
          />
        </Col>
      </Row>
      <Button
        className="mt-4"
        fullWidth
        clicked={verifyCustomer}
        bgColor="#2185aa"
        size="lg"
        color="#EBEBEB"
        loading={loading}
        disabled={loading}
      >
        Verify
      </Button>
      <p className={styles.extraTip}>
        ID is your national ID number
      </p>
    </div>
  );
};

export default BvnForm;
